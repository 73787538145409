<template>
  <div>
    <PlgBankServicesApi ref="PlgBankServicesApi"></PlgBankServicesApi>
    <Toasts ref="Toasts"></Toasts>
    <section v-show="!generated">
      <div class="row">
        <div class="col-12">
          <div class="card border rounded">
            <div class="card-body">
              <b-row>
                <b-col lg="11">
                  <h3 class="my-3 mx-3 text-center">Depositar via PIX</h3>
                </b-col>
                <b-col lg="1" class="py-3 d-none d-lg-block">
                  <router-link :to="{ name: 'home' }" style="color: black;">
                    <h3><i class="mdi mdi-arrow-left"></i></h3>
                  </router-link>
                </b-col>
              </b-row>
              <hr>
            </div>
            <plg-payment-amount-form
              :isLoading="isLoading"
              @formSubmit="generateChargeInfo"
            />
          </div>
        </div>
      </div>
    </section>
    <plg-pix-charge-info v-show="generated" 
      :amount="formattedAmount"
      :brCode="generatedChargeInfo.brCode">
    </plg-pix-charge-info>
  </div>
</template>

<script>
import PlgBankServicesApi from '../../apis/PlgBankServicesApi.vue';
import PlgPaymentAmountForm from '../../components/payments/PlgPaymentAmountForm.vue';
import Toasts from '../../components/Toasts.vue';
import PlgPixChargeInfo from './PlgPixChargeInfo.vue';


export default {
  data() {
    return {
      amount: 0,
      formattedAmount: null,
      toDefaultWorkspace: null,
      isLoading: false,
      generatedChargeInfo: {
        brCode: null,
      },
      generated: false,
    }
  },
  components: {
    PlgBankServicesApi,
    Toasts,
    PlgPaymentAmountForm,
    PlgPixChargeInfo,
  },
  methods: {
    async generateChargeInfo(amount) {
      this.toDefaultWorkspace = this.$route.params.toDefaultWorkspace
      this.amount = amount
      if(this.toDefaultWorkspace && this.amount) this.amount += 0.5
      const payload = {
        charge_data: {
          amount: this.amount,
          to_default_workspace: this.toDefaultWorkspace
        }
      }
      this.isLoading = true;
      let response = await this.$refs.PlgBankServicesApi.generateChargeInfo(payload);
      this.generated = true;
      this.isLoading = false;
      this.generatedChargeInfo.brCode = response.data.br_code
      this.formattedAmount = this.amount.toLocaleString('pt-BR', {minimumFractionDigits: 2})
      this.$refs.Toasts.showToast(response.data.status, response.data.message);
    },
  },
}
</script>
<template>
  <div class="row">
    <div class="col-12">
      <form @submit.prevent="formSubmit">
        <div class="container-fluid">
          <b-form-group
            class="mx-3"
            label="Informe a quantia desejada:"
            label-for="chargeAmount"
          >
            <money
              v-model="amount"
              id="chargeAmount"
              :disabled="disableFields"
              v-bind="money"
              maxlength="15"
              class="form-control form-control-sm"
              :class="{
                'is-invalid': $v.amount.$error,
              }"
            ></money>
            <div
              v-if="$v.amount.$error"
              class="invalid-feedback"
            >
              <span
                v-if="
                  !$v.amount.required ||
                  !$v.amount.minValue
                "
                >Campo obrigatório.</span
              >
            </div>
          </b-form-group>
          <div class="text-right mx-3 my-3">
            <b-row align-h="end">
              <b-col sm="1" md="2">
                <b-button
                  type="submit"
                  block
                  :disabled="isLoading || disableFields"
                  class="rounded"
                  variant="primary"
                  size="md"
                >
                  <i class="fa fa-check" v-if="!isLoading"></i>
                  <b-spinner v-else small></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";

export default {
  name: "PlgPaymentAmountForm",
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      amount: 0
    }
  },
  props: {
    disableFields: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  validations() {
    return {
      amount: {
        required,
        minValue: minValue(0.001)
      }
    }
  },
  methods: {
    formSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit("formSubmit", this.amount)
      }
    }
  }
}
</script>